/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}

ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}

a {
  display: block;
  width:100%;
  height: 100%;
}

input[type=search] {
  -moz-appearance:none;
  -webkit-appearance:none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}
/*********************  END RESET  *********************/


/*********************  Generic Classes  *********************/
body {
  line-height: 1;
  background: url(../../background.png);
}


/******************** LOADING *********************************/
.loading-container {
  text-align: center;
  font-family: 'Oxygen';
}

.loader {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 5px solid  #0ca13e;
  border-top-color: white;
  margin: 10% auto 5% auto;
  animation: spin 1s infinite linear;
}

/******************** NAVIGATION *********************************/
.navbar {
  margin: auto auto 10% auto;
  padding: 10px;
  background-color: #E5F3ED;
  font-family: 'Oxygen';
  font-size: 0.9em;
  border-radius: 0 0 20px 20px;
  box-shadow: 0px 0px 8px 0px #c9cccb;
}

.main-nav li {
  text-align: center;
  margin: 3% auto;
}

.create-link, .create-link:visited, .nav-link, .nav-link:visited, .trix-content a{
  color: #0ca13e;
  font-weight: bold;
}

.create-link {
  display: block;
  width: auto;
  margin: 5% auto;
  font-family: 'Oxygen';
}

/******************** HOME PAGE *********************************/
.flex-container-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.logo {
  align-self: center;
  margin: 3% 3% 5% 3%;
  width: 350px;
  background: radial-gradient(ellipse at center, rgb(244, 249, 247) 0%, rgb(244, 249, 247) 50%, rgba(244,249,247,0) 100%);
}

/******************** SEARCH BAR *********************************/
.search {
  margin: 5% auto 5% auto;
  display: block;
  width: 70%;

  font-family: 'Oxygen';
  font-size: 1em;
  line-height: 1.6;

  padding: 2% 3%;
  color: black;
  outline: 0;
  border: 0;
}

/******************** SEARCH RESULTS *********************************/
.result-list {
  text-align: center;
}

.result {
  text-align: left;
  margin: 4% 5%;
  padding: 3.5%;
}

.result a {
  text-decoration: none;
}

/******************** ARTICLE *********************************/
article {
  margin: 4% 5% 0% 5%;
  padding: 3% 3% 15% 3%;
}

.search, article, .result {
  background: #E5F3ED;
  box-shadow: 0px 0px 8px 0px #c9cccb;
  border-radius: 20px;
}

h1, .title-input {
  color: #0ca13e;
  font-weight: bold;
}

.title, .title-input {
  font-family: 'Rufina';
  font-size: 2.8em;
}

.title {
  border-bottom: 1px solid #aaaaaa;
  margin: 0 33% 7.5% auto;
}

.title-input {
  width: 95%;
  margin: 0 0 7.5% auto;
}

trix-toolbar {
  overflow: auto;
}

.trix-content {
  font-family: 'Oxygen';
}

.trix-content.edit{
  background: #F4F9F7;
  padding: 1em;
}

.trix-content > h1, .result h2 {
  color: #0ca13e;
  font-size: 1.5em;
  font-weight: bold;
  font-family: 'Rufina';
  margin: 0 33% 2% auto;
  border-bottom: 1px solid #aaaaaa;
}

.trix-content > div > em {
  font-style: italic;
}

.trix-content > div > strong {
  font-weight: bold;
}

.trix-content a {
  display: inline;
}

.text {
  font-family: 'Oxygen';
  font-size: 1em;
  line-height: 1.6;
  padding-left: 2px;
  color: black;
}

/******************** BUTTONS *********************************/
.edit-button {
  display: block;
  border: none;
  padding: 1rem 2rem;
  margin: 0 auto;
  width: 150px;
  text-decoration: none;
  background: #0ca13e;
  color: #ffffff;
  font-family: Oxygen;
  font-size: 1.5em;
  cursor: pointer;
  border-radius: 5px;
  text-align: center;
  transform: translateY(-50%);
  transition: background 125ms ease-in-out
              transform 75ms ease-in-out;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-decoration: none;
}

.edit-button:hover,
.edit-button:focus {
  background: #097e30;
}

.edit-button:focus {
  transform: translateY(-50%) scale(0.97);
}


/* Larger than mobile styling */
@media screen and (min-width: 900px) {
  body {
    font-size: 110%;
  }

  .logo {
    width: 400px;
  }

  .navbar {
    font-size: 1.1em;
    margin: auto auto 7.5% auto;
  }

  .result {
    margin: 2.5% 20% 2.5% 20%;
  }

  .search {
    margin: 3% auto 2% auto;
    padding: 1.5% 2%;
    border-radius: 40px;
    line-height: 1;
    font-size: 1.25em;
  }

  article {
    margin: 5% 20% 0% 20%;
    padding: 3% 3% 6% 3%;
  }

  .title {
    margin: 0 33% 5% auto;
  }

  .text {
    line-height: 1.75;
  }
}

@media screen and (min-width: 1500px) {
  body {
    font-size: 125%;
  }

  .navbar {
    font-size: 1.1em;
    margin: auto auto 5% auto;
  }

  .create-link {
    margin: 2% auto;
  }

  .logo {
    width: auto;
  }

  .search {
    margin: 3% auto 4% auto;
    padding: 1.5% 2%;
    border-radius: 60px;
  }

  .result {
    margin: 1.5% 25% 1.5% 25%;
  }

  article {
    margin: 3% 25% 0% 25%;
    padding: 3% 5% 4% 5%;
  }
}


@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
